.topGroup {
  flex-grow: 3;
  flex-direction: column;
  gap: var(--chakra-space-4);
  margin-top: var(--chakra-space-4);
}

.wrapper {
  flex-direction: column;
  justify-content: space-between;
  min-height: calc(var(--chakra-sizes-96));
  max-height: calc(var(--chakra-sizes-96) * 1.5);
}

.lowerBox {
  flex-grow: 2;
  border: var(--chakra-borders-1px) var(--chakra-colors-primary-200);
  border-radius: var(--chakra-radii-base);
  padding: var(--chakra-space-2);
  min-height: 96px;
}

.radioButton {
  color: var(--chakra-colors-primary-800);
}

.label {
  margin-bottom: calc(var(--chakra-space-4) * (0.625));
  color: var(--chakra-colors-primary-800);
  font-weight: var(--chakra-fontWeights-bold);
  font-size: var(--chakra-fontSizes-md);
}

.greyLabel {
  color: var(--chakra-colors-secondary-800);
}
