.menu {
  display: flex;
  position: relative;
  margin: 0;
  padding: 0;
  list-style: none;
}

.horizontal {
  flex-direction: row;

  &.bordered {
    border-bottom: var(--menu-border);

    a {
      position: relative;
    }
  }
}

.vertical {
  flex-direction: column;

  &.bordered {
    border-right: var(--menu-border);
  }
}

.closed {
  display: none;
}
