.xNavigation {
  width: fit-content;
  height: 100%;
}

.yNavigation {
  position: fixed;
  transition: width, min-width 0.3s ease-out;
  width: 0;
  min-width: 0;
  overflow: hidden;
}

.menu {
  display: flex;
  align-items: flex-end;
  overflow: hidden;
}

.xMenu {
  padding: 0 var(--chakra-space-8);
}

.yMenu {
  flex-direction: column;
  justify-content: flex-start;
  gap: var(--chakra-space-3);
  visibility: hidden;
  transition: visibility 0.3s;
  padding: var(--chakra-space-8) var(--chakra-space-6);
  width: 100%;
  overflow-y: auto;

  &.open {
    visibility: visible;
  }
}

.xNavItem {
  margin-left: var(--chakra-space-3);
  min-width: 135px;

  &.xChildNavItem {
    min-width: 151px;
  }

  &:first-child {
    margin-left: 0;
  }
}

.yNavItem {
  width: 100%;
}

.xNavLink {
  justify-content: center;
  border-radius: 6px 6px 0 0;
  height: 44px;

  &.xChildNavLinkHeight {
    border-radius: 10px 10px 0 0;
    height: 46px;

    &:global(.active) {
      height: 73px;
    }
  }

  &:global(.active) {
    border-bottom: none;
    height: 58px;
  }
}
