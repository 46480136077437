.card {
  &:hover {
    background-color: #dff0ff;
  }

  &.active {
    background-color: #dff0ff;

    &:hover {
      background-color: #abcae7;
    }
    &.isBelowK3 {
      &::before {
        position: absolute;
        bottom: 0;
        left: 9%;
        z-index: 1;
        border-top-right-radius: 10px;
        border-top-left-radius: 10px;
        background: #8fb7dc;
        width: 82%;
        height: 7px;
        content: '';
      }

      &:hover::before {
        position: absolute;
        bottom: 0;
        left: 9%;
        z-index: 1;
        border-top-right-radius: 10px;
        border-top-left-radius: 10px;
        background: #225b90;
        width: 82%;
        height: 7px;
        content: '';
      }
    }
  }
}

.link {
  &:focus-visible {
    box-shadow: none;

    &::before {
      box-shadow: 0 0 0 4px var(--chakra-colors-blue-600);
    }
  }
}
