.wrapper {
  :global(.lrn-report-item-title .lrn-circle) {
    display: none;
  }

  :global(.lrn-report-item-title) {
    display: none;
  }

  :global(.lrn_widget) {
    padding-top: 0;
  }

  :global(.learnosity-question-container .col-md-6) {
    margin-right: 1rem;
    margin-left: 1rem;
    padding-right: 0;
    padding-left: 0;
  }

  :global(.col-xs-6) {
    overflow-x: auto;
  }

  :global(.table-question) {
    overflow-x: auto;
  }

  @media (max-width: 767px) {
    :global(.col-xs-6),
    :global(.col-xs-5),
    :global(.col-xs-7) {
      min-width: 100% !important;
    }
  }
}
