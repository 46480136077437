.label {
  padding: var(--menu-group-label-padding);
  color: var(--basic-800);
  font-weight: var(--menu-group-label-font-weight);
  font-size: 0.75rem;
  line-height: 1rem;
}

.contentWrapper {
  padding: 0;
  list-style: none;

  /* Higher specifity for overriding `SubMenu` className. */

  &.noBorderRadius {
    & > li:first-child,
    & > li:last-child {
      & > * {
        border-radius: 0;
      }
    }
  }
}
