.overlay {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  justify-content: center;
  align-items: center;
  z-index: 10;
  background-color: rgba(236, 236, 236, 0.7);
  width: 100%;
  height: 100%;
}

.wrapper {
  display: flex;
  position: relative;
  flex-direction: column;
  border-radius: 1px;
  background-color: var(--french-grey-5);
  padding: var(--global-grid-size) calc(var(--global-grid-size) * 5)
    calc(var(--global-grid-size) * 5);
  width: 606px;

  & > .closeButton {
    align-self: flex-end;
    cursor: pointer;
    margin-bottom: calc(var(--global-grid-size) / 2);
    color: var(--white);

    &[class*='Button_primary'],
    &[class*='button_primary']:hover {
      background-color: transparent;
      padding: 0;
    }

    & svg {
      width: 19px;
      height: 19px;
    }
  }
}

.content {
  border-radius: 4px;
  background-color: var(--white);
  padding: calc(var(--global-grid-size) * 4);
}
