.content {
  font-style: italic;

  .loading-ellipsis::after {
    animation: ellipsis 1s infinite steps(3);
    content: '';
  }
}

@keyframes ellipsis {
  0% {
    content: '.';
  }
  33% {
    content: '..';
  }
  66% {
    content: '...';
  }
}
