.wrapper {
  display: flex;
  position: relative;
  align-items: center;
}

.list {
  display: flex;
  position: relative;
  margin: 0;
  padding: 0;
  list-style: none;
}

.horizontal {
  flex-direction: row;

  &.bordered {
    border-bottom: var(--menu-border);

    a {
      position: relative;
    }
  }
}

.vertical {
  flex-direction: column;

  &.bordered {
    border-right: var(--menu-border);
  }
}

.closed {
  display: none;
}

.scrollButton {
  flex-shrink: 0;
  background: transparent;
  padding: 0;

  &:first-of-type {
    margin-right: calc(var(--global-grid-size) * 2);
  }

  &:last-of-type {
    margin-left: calc(var(--global-grid-size) * 2);
  }

  &:disabled,
  &:disabled:hover {
    border-color: transparent;
    background: transparent;

    & span {
      color: var(--grey-2);
    }
  }
}

.scrollLeftButton {
  transform: scaleX(-1);
}

.subMenu {
  overflow: hidden;
}
