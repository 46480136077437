.action {
  border: 1px solid transparent;
  padding: 0.25rem 0.75rem;
  height: 100%;
  color: var(--blue-5);

  &:hover {
    background-color: var(--chakra-colors-primary-30);
    color: var(--blue-5);
  }

  & [class*='SubMenu_subMenuSuffix'] {
    margin: 0;
    width: var(--button-line-height);
    height: var(--button-line-height);
  }
}

.menuContainer {
  right: 0;
  padding: 0;

  & .menu {
    border: 0.5px solid var(--blue-5);
    border-radius: 0;
    background-color: var(--grey-2);
    min-width: 136px;

    & .subMenuLabel {
      display: flex;
      border-radius: 0;
      background-color: var(--blue-5);
      padding: 10px var(--global-grid-size);
      color: var(--white);
    }
  }
}

.leftSideDDL {
  right: 0;
  left: auto;

  & > div {
    border-radius: 0;
  }
}

.menuItem {
  padding: calc(var(--global-grid-size) / 2);

  &:not(:last-child) {
    border-bottom: 0.5px solid var(--blue-5);
  }

  &:hover {
    background-color: var(--primary-100);
    color: var(--blue-5);
  }
}

.ddlButton {
  display: inline-flex;
  justify-content: flex-start;
  padding: 6px var(--global-grid-size);
  width: 100%;
  color: var(--blue-5);
  white-space: normal;
}
