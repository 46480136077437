.uploadInput {
  position: inherit;
  z-index: 2;
  cursor: pointer;
  border-radius: 4px;
  width: 100%;
  min-width: 300px;
  min-height: 262px;
  color: transparent;

  &::-webkit-file-upload-button {
    visibility: hidden;
  }
}
