.label {
  color: var(--lonestar---basic---basic-800);
  font-weight: var(--typography-open-sans-font-weight-h5);
  font-size: var(--typography-open-sans-font-size-h5);
  line-height: var(--typography-open-sans-line-height-h5);
}

.input {
  border-color: var(--lonestar---basic---basic-400);
  border-radius: 3px;
  background-color: var(--white);
  color: var(--lonestar---basic---basic-800);
  font-weight: var(--typography-p2-font-weight);
  font-size: var(--typography-h5-font-size);
  line-height: var(--typography-p2-line-height);
  text-overflow: ellipsis;

  &:hover {
    border-color: var(--lonestar---basic---basic-800);
    background-color: var(--white);
  }

  &:focus {
    outline-color: transparent;
    outline-style: solid;
    border-color: var(--lonestar---basic---basic-800);
    background-color: var(--white);
  }

  &::placeholder {
    color: var(--lonestar---basic---basic-700);
  }

  &:disabled,
  &[disabled],
  &[disabled]:hover {
    border-color: var(--lonestar---basic---basic-400);
    background-color: var(--lonestar---basic---basic-200);
    color: var(--lonestar---basic---basic-400);

    &::placeholder {
      color: var(--lonestar---basic---basic-400);
    }
  }

  & + *[class*='UI_Input_icon'],
  [data-focus-visible] & + *[class*='UI_Input_icon'] {
    fill: var(--lonestar---basic---basic-400);
    color: var(--lonestar---basic---basic-400);
  }

  &[data-focus-visible] {
    outline-color: transparent;
    outline-style: solid;
    box-shadow: 0px 0px 0px 4px rgba(191, 239, 255, 1);
    border-color: var(--lonestar---info---info-600);
  }
}

.dark {
  .label {
    color: var(--white);
  }

  .input {
    border-color: var(--lonestar---primary---primary-900);

    &:hover {
      border-color: var(--lonestar---info---info-600);
    }

    &:focus {
      border-color: var(--lonestar---info---info-600);
    }

    &:disabled,
    &[disabled],
    &[disabled]:hover {
      border-color: var(--lonestar---basic---basic-700);
      background-color: var(--lonestar---basic---basic-400);
      color: var(--lonestar---basic---basic-700);

      &::placeholder {
        color: var(--lonestar---basic---basic-700);
      }

      & + *[class*='UI_Input_icon'] {
        fill: var(--lonestar---basic---basic-700);
        color: var(--lonestar---basic---basic-700);
      }
    }
  }
}

.clearButton {
  &:hover {
    background-color: transparent;
    color: var(--basic-600);
  }

  &:active {
    background-color: transparent;
    color: var(--basic-600);
  }

  &[data-focus-visible] {
    outline: initial;
    outline-color: transparent;
    outline-style: solid;
  }
}
