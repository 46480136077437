/* stylelint-disable */

.react-datepicker__input-container:hover {
  border-color: hsl(0, 0%, 70%);
}

.react-datepicker__input-container > input {
  outline: 0;
  width: 100%;
  height: 100%;
}

.react-datepicker-popper {
  right: 0 !important;
  z-index: 10;
  width: min-content;
  min-width: 250px;
}

.react-datepicker-popper[data-placement^='top'] {
  padding-bottom: 0;
}

.react-datepicker {
  box-shadow: 0px 5px 10px -2px rgba(0, 0, 0, 0.15);
  border: none;

  width: 100%;
  font-size: 0.9rem;
  font-family: unset;

  & * {
    color: var(--chakra-colors-primary-800);
  }
}

.react-datepicker__triangle {
  display: none;
}

.react-datepicker__close-icon {
  right: 40px;

  &:after {
    background-color: var(--chakra-colors-primary-700);
  }
}

.react-datepicker__navigation--previous,
.react-datepicker__navigation--next {
  top: 5px;
  height: 40px;
}

.react-datepicker__navigation--previous {
  left: 12px;

  & .react-datepicker__navigation-icon--previous {
    right: 2px;
  }
}

.react-datepicker__navigation--next {
  right: 12px;

  & .react-datepicker__navigation-icon--next {
    left: 2px;
  }
}

.react-datepicker__navigation-icon {
  top: 8px;
}

.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow,
.react-datepicker__navigation-icon::before {
  border-width: 1px 1px 0 0;
  border-color: var(--chakra-colors-primary-800);
  width: 6px;
  height: 6px;
}

.react-datepicker__day-names,
.react-datepicker__week {
  display: flex;
}

.react-datepicker__header {
  background: var(--chakra-colors-white);
  padding: 0 0 var(--chakra-space-2);

  & .react-datepicker__current-month,
  & .react-datepicker-time__header,
  & .react-datepicker-year-header {
    font-weight: 600;
    font-size: inherit;
  }

  & .react-datepicker__day-names {
    margin-top: var(--chakra-space-2);
    border-top: 1px solid #e2e8f0;

    & .react-datepicker__day-name {
      text-transform: uppercase;
    }
  }
}

.react-datepicker__header,
.react-datepicker__time-container {
  border-color: #e2e8f0;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  flex-grow: 1;
  margin: 2px;
  border-radius: 4px;
  height: 31px;
  font-size: 12px;
  line-height: 31px;
}

.react-datepicker__month-container {
  padding: var(--chakra-space-4);
  width: 100%;
}

.react-datepicker__day--today,
.react-datepicker__month-text--today,
.react-datepicker__quarter-text--today,
.react-datepicker__year-text--today {
  outline: 2px solid var(--chakra-colors-primary-400);
  outline-offset: -2px;
  border-radius: 4px;
  font-weight: normal;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  background-color: var(--chakra-colors-white);
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__time-container,
.react-datepicker__time,
.react-datepicker__time-box,
ul.react-datepicker__time-list,
li.react-datepicker__time-list-item--selected {
  box-shadow: none;
  background-color: var(--chakra-colors-primary-800);
  color: var(--chakra-colors-white);
}

.react-datepicker__day {
  &.react-datepicker__day--selected:hover,
  &.react-datepicker__day--in-selecting-range:hover,
  &.react-datepicker__day--in-range:hover,
  &.react-datepicker__month-text--selected:hover,
  &.react-datepicker__month-text--in-selecting-range:hover,
  &.react-datepicker__month-text--in-range:hover,
  &.react-datepicker__quarter-text--selected:hover,
  &.react-datepicker__quarter-text--in-selecting-range:hover,
  &.react-datepicker__quarter-text--in-range:hover,
  &.react-datepicker__year-text--selected:hover,
  &.react-datepicker__year-text--in-selecting-range:hover,
  &.react-datepicker__year-text--in-range:hover {
    background-color: #1d5d90;
    color: var(--chakra-colors-white);
  }

  &.react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range),
  &.react-datepicker__month-text--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range),
  &.react-datepicker__quarter-text--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range),
  &.react-datepicker__year-text--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range) {
    background-color: rgba(33, 107, 165, 0.5);
  }
}

.react-datepicker__day--keyboard-selected:hover,
.react-datepicker__month-text--keyboard-selected:hover,
.react-datepicker__quarter-text--keyboard-selected:hover,
.react-datepicker__year-text--keyboard-selected:hover {
  background-color: #f0f0f0;
}

.react-datepicker__day--disabled,
.react-datepicker__month-text--disabled,
.react-datepicker__quarter-text--disabled,
.react-datepicker__year-text--disabled {
  cursor: default;
  color: var(--chakra-colors-secondary-300);
}

.react-datepicker__day--highlighted-custom-1 {
  box-shadow: inset 0 0 0 1px #abcae7;
  border-radius: 4px;
  background: #f2f9ff;
  color: #1e486f;

  &.react-datepicker__day--today,
  &.react-datepicker__month-text--today,
  &.react-datepicker__quarter-text--today,
  &.react-datepicker__year-text--today {
    box-shadow: inset 0 0 0 2px #abcae7;
  }

  &.react-datepicker__day--selected,
  &.react-datepicker__day--in-selecting-range,
  &.react-datepicker__day--in-range,
  &.react-datepicker__month-text--selected,
  &.react-datepicker__month-text--in-selecting-range,
  &.react-datepicker__month-text--in-range,
  &.react-datepicker__time-container,
  &.react-datepicker__time,
  &.react-datepicker__time-box,
  &ul.react-datepicker__time-list,
  &li.react-datepicker__time-list-item--selected {
    box-shadow: none;
    background-color: #1e486f;
    color: #f2f9ff;
  }

  &.react-datepicker__day--disabled,
  &.react-datepicker__month-text--disabled,
  &.react-datepicker__quarter-text--disabled,
  &.react-datepicker__year-text--disabled {
    opacity: 0.7;

    &:hover {
      background-color: #f2f9ff;
    }
  }
}

.react-datepicker__day--highlighted-custom-2 {
  box-shadow: inset 0 0 0 1px var(--chakra-colors-olive-75);
  border-radius: 4px;
  background: var(--chakra-colors-olive-50);
  color: var(--chakra-colors-olive-100);

  &.react-datepicker__day--today,
  &.react-datepicker__month-text--today,
  &.react-datepicker__quarter-text--today,
  &.react-datepicker__year-text--today {
    box-shadow: inset 0 0 0 2px var(--chakra-colors-olive-75);
  }

  &.react-datepicker__day--selected,
  &.react-datepicker__day--in-selecting-range,
  &.react-datepicker__day--in-range,
  &.react-datepicker__month-text--selected,
  &.react-datepicker__month-text--in-selecting-range,
  &.react-datepicker__month-text--in-range,
  &.react-datepicker__time-container,
  &.react-datepicker__time,
  &.react-datepicker__time-box,
  &ul.react-datepicker__time-list,
  &li.react-datepicker__time-list-item--selected {
    box-shadow: none;
    background-color: var(--chakra-colors-olive-100);
    color: var(--chakra-colors-olive-50);
  }

  &.react-datepicker__day--disabled,
  &.react-datepicker__month-text--disabled,
  &.react-datepicker__quarter-text--disabled,
  &.react-datepicker__year-text--disabled {
    opacity: 0.7;

    &:hover {
      background-color: var(--chakra-colors-olive-50);
    }
  }
}

.react-datepicker__day--highlighted-custom-3 {
  box-shadow: inset 0 0 0 1px var(--chakra-colors-yellow-100);
  border-radius: 4px;
  background: var(--chakra-colors-warning-extraLight);
  color: var(--chakra-colors-warning-text);

  &.react-datepicker__day--today,
  &.react-datepicker__month-text--today,
  &.react-datepicker__quarter-text--today,
  &.react-datepicker__year-text--today {
    box-shadow: inset 0 0 0 2px var(--chakra-colors-yellow-100);
  }

  &.react-datepicker__day--selected,
  &.react-datepicker__day--in-selecting-range,
  &.react-datepicker__day--in-range,
  &.react-datepicker__month-text--selected,
  &.react-datepicker__month-text--in-selecting-range,
  &.react-datepicker__month-text--in-range,
  &.react-datepicker__time-container,
  &.react-datepicker__time,
  &.react-datepicker__time-box,
  &ul.react-datepicker__time-list,
  &li.react-datepicker__time-list-item--selected {
    box-shadow: none;
    background-color: var(--chakra-colors-warning-text);
    color: var(--chakra-colors-warning-extraLight);
  }

  &.react-datepicker__day--disabled,
  &.react-datepicker__month-text--disabled,
  &.react-datepicker__quarter-text--disabled,
  &.react-datepicker__year-text--disabled {
    opacity: 0.7;

    &:hover {
      background-color: var(--chakra-colors-warning-extraLight);
    }
  }
}

.react-datepicker__day--highlighted-custom-4 {
  box-shadow: inset 0 0 0 1px #ffb05f;
  border-radius: 4px;
  background: var(--chakra-colors-orange-extraLight);
  color: var(--chakra-colors-orange-900);

  &.react-datepicker__day--today,
  &.react-datepicker__month-text--today,
  &.react-datepicker__quarter-text--today,
  &.react-datepicker__year-text--today {
    box-shadow: inset 0 0 0 2px #ffb05f;
  }

  &.react-datepicker__day--selected,
  &.react-datepicker__day--in-selecting-range,
  &.react-datepicker__day--in-range,
  &.react-datepicker__month-text--selected,
  &.react-datepicker__month-text--in-selecting-range,
  &.react-datepicker__month-text--in-range,
  &.react-datepicker__time-container,
  &.react-datepicker__time,
  &.react-datepicker__time-box,
  &ul.react-datepicker__time-list,
  &li.react-datepicker__time-list-item--selected {
    box-shadow: none;
    background-color: var(--chakra-colors-orange-900);
    color: var(--chakra-colors-orange-extraLight);
  }

  &.react-datepicker__day--disabled,
  &.react-datepicker__month-text--disabled,
  &.react-datepicker__quarter-text--disabled,
  &.react-datepicker__year-text--disabled {
    opacity: 0.7;

    &:hover {
      background-color: var(--chakra-colors-orange-extraLight);
    }
  }
}

.react-datepicker__day--highlighted-custom-5 {
  box-shadow: inset 0 0 0 1px #ffa5eb;
  border-radius: 4px;
  background: #ffecfa;
  color: #780a60;

  &.react-datepicker__day--today,
  &.react-datepicker__month-text--today,
  &.react-datepicker__quarter-text--today,
  &.react-datepicker__year-text--today {
    box-shadow: inset 0 0 0 2px #ffa5eb;
  }

  &.react-datepicker__day--selected,
  &.react-datepicker__day--in-selecting-range,
  &.react-datepicker__day--in-range,
  &.react-datepicker__month-text--selected,
  &.react-datepicker__month-text--in-selecting-range,
  &.react-datepicker__month-text--in-range,
  &.react-datepicker__time-container,
  &.react-datepicker__time,
  &.react-datepicker__time-box,
  &ul.react-datepicker__time-list,
  &li.react-datepicker__time-list-item--selected {
    box-shadow: none;
    background-color: #780a60;
    color: #ffecfa;
  }

  &.react-datepicker__day--disabled,
  &.react-datepicker__month-text--disabled,
  &.react-datepicker__quarter-text--disabled,
  &.react-datepicker__year-text--disabled {
    opacity: 0.7;

    &:hover {
      background-color: #ffecfa;
    }
  }
}

.react-datepicker__day--highlighted-custom-6 {
  box-shadow: inset 0 0 0 1px #c1aeda;
  border-radius: 4px;
  background: #efedff;
  color: #583f79;

  &.react-datepicker__day--today,
  &.react-datepicker__month-text--today,
  &.react-datepicker__quarter-text--today,
  &.react-datepicker__year-text--today {
    box-shadow: inset 0 0 0 2px #c1aeda;
  }

  &.react-datepicker__day--selected,
  &.react-datepicker__day--in-selecting-range,
  &.react-datepicker__day--in-range,
  &.react-datepicker__month-text--selected,
  &.react-datepicker__month-text--in-selecting-range,
  &.react-datepicker__month-text--in-range,
  &.react-datepicker__time-container,
  &.react-datepicker__time,
  &.react-datepicker__time-box,
  &ul.react-datepicker__time-list,
  &li.react-datepicker__time-list-item--selected {
    box-shadow: none;
    background-color: #583f79;
    color: #efedff;
  }

  &.react-datepicker__day--disabled,
  &.react-datepicker__month-text--disabled,
  &.react-datepicker__quarter-text--disabled,
  &.react-datepicker__year-text--disabled {
    opacity: 0.7;

    &:hover {
      background-color: #efedff;
    }
  }
}

.react-datepicker__input-time-container
  .react-datepicker-time__input-container
  .react-datepicker-time__input
  input {
  display: inline-block;
  margin-left: 10px;
  box-shadow: inset 0 2px 2px #e9e9e9;
  border: 1px solid #aeaeae;
  border-radius: 4px;
  padding: 6px 10px 5px;
  font-size: 13px;
  line-height: 16px;
}

.info-circle {
  display: inline-block;
  margin-right: 12px;
  border: 1px solid;
  border-radius: 50%;
  min-width: 16px;
  min-height: 16px;

  &.blue {
    border-color: #abcae7;
    background-color: #f2f9ff;
  }

  &.olive {
    border-color: var(--chakra-colors-olive-75);
    background-color: var(--chakra-colors-olive-50);
  }

  &.yellow {
    border-color: var(--chakra-colors-yellow-100);
    background-color: var(--chakra-colors-warning-extraLight);
  }

  &.orange {
    border-color: #ffb05f;
    background-color: var(--chakra-colors-orange-extraLight);
  }

  &.fuchsia {
    border-color: #ffa5eb;
    background-color: #ffecfa;
  }

  &.violet {
    border-color: #c1aeda;
    background-color: #efedff;
  }
}

.react-datepicker__year-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: fit-content;
}

.react-datepicker__year .react-datepicker__year-text {
  display: inline-block;
  margin: 0;
  padding: var(--chakra-space-2) var(--chakra-space-4);
  width: auto;
}

.react-datepicker__year {
  margin: 0;
}

.react-datepicker-year-header {
  margin: 0;
  padding-top: 0;
  padding-bottom: var(--chakra-space-2);
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  border-radius: 0.3rem;
  background-color: var(--chakra-colors-primary-800);
  color: var(--chakra-colors-white);
}

.react-datepicker__year--container {
  padding: var(--chakra-space-4);
}

.react-datepicker__day--outside-month {
  color: var(--chakra-colors-secondary-300);
}

.react-datepicker__year {
  margin-top: var(--chakra-space-4);
}
