.wrapper {
  flex-grow: 1;
  margin: 0 auto;
  padding: 0 0 var(--page-content-padding-bottom);
  width: 100%;
  max-width: var(--page-width-max);
}

.stretch {
  flex-grow: 0;
  flex-shrink: 0;
  padding: 0;
  max-width: none;
}

.container {
  margin: 0 auto;
  padding: var(--page-content-padding-top) var(--global-gap)
    calc(var(--global-grid-size) * 4);
  width: 100%;
  max-width: var(--page-width-max);
}
