.icon {
  display: flex;

  svg {
    width: 20px;
    height: 20px;
  }
}

.iconK2 {
  svg {
    width: 18px;
    height: 18px;
  }
}
