.accordion {
  border: none;
  background: var(--white);

  & .chakra-accordion__item {
    border-bottom-width: 0;
  }
}

.header {
  padding: calc(var(--global-grid-size) * 3.5);

  button {
    border-bottom: 1px solid var(--blue-5);
    padding: 5px 0;
    color: var(--blue-5);

    &:hover {
      background: transparent;
    }
  }
}

.panel {
  padding: 0 calc(var(--global-grid-size) * 3.5)
    calc(var(--global-grid-size) * 5);
}

.expanded {
  rotate: 180deg;
}
