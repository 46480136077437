.separator {
  display: flex;
  position: relative;
  align-items: center;
  margin: 0 1px;

  &::after {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(0, -50%);
    background-color: var(--blue-5);
    width: 1px;
    height: 20px;
    content: '';
  }
}

.button {
  padding: var(--button-md-padding-tb) 0;
  min-width: 30px;
}

.iconWrapper {
  & svg {
    width: 15px;
    height: 15px;
  }
}
