.partType {
  margin-top: var(--global-gap);

  &:first-child {
    margin-top: 0;
  }
}

.hiddenElementPart {
  clip: rect(0 0 0 0);
  position: absolute;
  margin: -1px;
  border: 0;
  padding: 0;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

.iframe {
  border: 0;
  background-color: var(--white);
  width: 100%;
  min-height: 100%;

  @media print {
    border: 1px solid var(--basic-400);
  }

  :global(.theme-high-contrast) & {
    @media not print {
      border: var(--global-border);
    }
  }
}

.hide {
  visibility: hidden;
}
