.confirmContent {
  border: none;
  padding: var(--chakra-space-6);
}

.bodyTextContainer {
  gap: var(--chakra-space-2);
  border-radius: 6px;
  background: #eff8ff;
  padding: var(--chakra-space-6);
}

.textHeading {
  color: var(--chakra-colors-blue-500);
  font-weight: var(--chakra-fontWeights-bold);
}

.infoText {
  color: var(--chakra-colors-blue-500);
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.625rem;
}

.cancelBtns {
  border-width: var(--chakra-borders-2px);
  border-color: var(--chakra-colors-blue-500);
  background-color: var(--chakra-colors-white);
  color: var(--chakra-colors-blue-500);

  &:hover {
    border-color: var(--chakra-colors-blue-100);
    background-color: var(--chakra-colors-white);
    color: var(--chakra-colors-blue-500);
  }
}

.dangerTextContainer {
  margin-bottom: calc(var(--chakra-space-4) * 1.125);
  background-color: var(--lonestar---danger---danger-100);
  padding: var(--chakra-space-6);
}

.saveBtn {
  background-color: var(--chakra-colors-blue-500);
  color: var(--chakra-colors-white);
}

.deleteBtn {
  border-width: calc(var(--button-border-width) * 2);
  border-color: var(--red-4);
  background-color: var(--chakra-colors-white);

  &:hover {
    border-color: var(--chakra-colors-blue-100);
    background-color: var(--chakra-colors-white);
    color: var(--chakra-colors-blue-500);
  }
}

.dangerHeading {
  color: var(--chakra-colors-danger-800);
  font-weight: var(--chakra-fontWeights-semibold);
}

@media all and (max-width: 767px) {
  .bodyTextContainer {
    flex: 1;
  }

  .dangerTextContainer {
    flex: 1;
  }

  .confirmContent {
    background: #eff8ff;
    padding: 0;
  }
}
