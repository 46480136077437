.scrollBar {
  &::-webkit-scrollbar {
    background-color: rgba(30, 65, 118, 0.1);
    width: 10px;
    height: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background: var(--chakra-colors-primary-500);
  }
}
