.button {
  padding: var(--button-md-padding-tb) 0;
  width: 30px;
  min-width: 30px;
}

.iconWrapper {
  & svg {
    width: 15px;
    height: 15px;
  }
}
