.overlay {
  z-index: 11;
  background: rgba(0, 0, 0, 0.3);

  & .content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  & .cropImage {
    max-height: 50vh;
  }
}
