.listItem {
  &:hover {
    background-color: var(--primary-100);
  }

  &:not(:last-child) {
    border-bottom: 1px solid var(--blue-5);
  }
}

.ddlButton {
  display: inline-flex;
  justify-content: flex-start;
  padding: calc(var(--global-grid-size) * 1.25)
    calc(var(--global-grid-size) * 1.5);
  width: 100%;
  min-width: 198px;
  min-height: calc(var(--global-grid-size) * 5.25);
  color: var(--blue-5);
  white-space: normal;
  word-break: break-word;
}
