.wrapper {
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: center;
  box-shadow: var(--global-shadow);
  background-color: var(--chakra-colors-primary-900);
  padding: calc(var(--global-grid-size) / 2) calc(var(--global-grid-size) + 4px);
  font-family: var(--font-type-main);
}

.wrapper > ul {
  padding: 4px;
}

.menuContent {
  overflow: hidden;
  white-space: nowrap;
}

.menuList {
  display: inline-flex;
  transition: left 400ms linear;
}

.link {
  margin-left: var(--global-grid-size);
  text-decoration: none;

  &[data-focus-visible] {
    outline: none;

    & > .buttonAll {
      box-shadow: var(--global-outline);
      background-color: var(--white);
      color: var(--blue-5);
    }
  }
}

.viewAllButton {
  border: 4px solid var(--white);
  padding: 0 var(--global-grid-size);

  &:active {
    border: 4px solid var(--blue-2);
  }

  &[data-focus-visible] {
    border: 4px solid var(--blue-2);
    text-decoration: none;
  }
}
