.sidebar {
  position: absolute;
  top: 0;
  clip-path: inset(0px -70px 0px 0px);
  transition: width, min-width 0.3s ease-out;
  background-color: var(--chakra-colors-primary-800);
  width: 0;
  min-width: 0;
  height: 100vh;

  &.open {
    box-shadow: var(--chakra-shadows-3xl);
    min-width: 337px;
  }

  @media all and (min-width: 768px) {
    top: auto;
    height: calc(100vh - 76px);
  }

  @media all and (min-width: 1281px) {
    position: relative;
    height: 100%;

    &.open {
      min-width: 337px;
    }

    &.childSize {
      height: calc(100vh - 142px);
    }
  }

  @media print {
    display: none;
  }
}

.scrollBar {
  height: calc(100vh - 142px);
  &::-webkit-scrollbar {
    background-color: rgba(255, 255, 255, 0.15);
    width: 10px;
    height: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background: var(--chakra-colors-primary-300);
  }
}

.opener {
  position: absolute;
  right: 0;
  transition: var(--global-transition);
  padding: 0;

  &:hover {
    background: transparent;
  }

  &[data-focus-visible] {
    position: absolute;
  }
}

.content {
  display: flex;
  flex-direction: column;
  min-width: 337px;
  height: 100%;

  @media all and (min-width: 1281px) {
    min-width: 337px;
  }
}
