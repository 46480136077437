.link {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: var(--global-grid-size) calc(var(--global-grid-size) * 2)
    var(--global-grid-size) calc(var(--global-grid-size) * 3);
  width: 100%;
  min-height: calc(var(--global-grid-size) * 5);
}

.sectionMenuButton {
  background-color: var(--chakra-colors-primary-900);

  &:hover {
    background: var(--chakra-colors-primary-700);
  }

  &:active {
    background: var(--chakra-colors-primary-400);
  }

  &[data-active] {
    background: var(--chakra-colors-primary-900);
  }

  &:disabled,
  &:disabled:hover {
    background: var(--chakra-colors-primary-900);
    color: var(--chakra-colors-primary-400);
  }
}

.activeSection {
  z-index: 1;
  border: 1px solid var(--chakra-colors-white);
  background-color: var(--chakra-colors-primary-900);
  text-decoration: none;
}
