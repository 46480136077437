.wrapper {
  border-radius: var(--global-border-radius);

  &:link {
    color: var(--primary-500);
  }

  &:visited {
    color: var(--info-700);
  }

  &:hover {
    color: var(--primary-400);
    text-decoration: none;
  }

  &:active {
    color: var(--primary-600);
  }

  &:focus {
    outline-color: transparent;
    outline-style: solid;
  }

  &[data-focus-visible] {
    box-shadow: var(--global-outline);
  }
}

.isDisabled {
  cursor: not-allowed;

  a {
    pointer-events: none;
    text-decoration: none;
  }
}
