.wrapper {
  position: relative;
}

.opener {
  position: relative;
  box-sizing: border-box;
  border: 0;
  background: transparent;
  pointer-events: auto;
  color: var(--basic-600);

  &::after {
    display: none;
    position: absolute;
    border-radius: 2rem;
    content: '';
  }

  &:hover {
    border: 0;
    background: transparent;
    color: var(--primary-400);
  }

  &[data-focus-visible] {
    background: transparent;
    color: var(--primary-500);
  }
}

.openerSmall {
  padding: var(--menu-item-sm-padding);
  font-weight: var(--menu-item-sm-font-weight);
}

.openerMedium {
  padding: var(--menu-item-md-padding);
  font-weight: var(--menu-item-md-font-weight);
}

.openerLarge {
  padding: var(--menu-item-lg-padding);
  font-weight: var(--menu-item-lg-font-weight);
}

.verticalOpener {
  justify-content: space-between;
  width: 100%;
}

.verticalBordered {
  left: var(--menu-border-width);

  &::after {
    top: 0;
    right: 0;
    width: var(--menu-border-width);
    height: 100%;
  }

  &:hover {
    &::after {
      display: block;
      background: var(--primary-200);
    }
  }

  &[data-focus-visible] {
    &::after {
      display: block;
      background: var(--primary-500);
    }
  }
}

.horizontalBordered {
  top: var(--menu-border-width);

  &::after {
    bottom: 0;
    left: 0;
    width: 100%;
    height: var(--menu-border-width);
  }

  &:hover {
    &::after {
      display: block;
      background: var(--primary-200);
    }
  }

  &[data-focus-visible] {
    &::after {
      display: block;
      background: var(--primary-500);
    }
  }
}

.popupVerticalWrapper {
  position: absolute;
  top: 0;
  left: 100%;
}

.popupScrollbar {
  position: absolute;
  top: 0;
  left: 100%;
}

.popupHorizontalWrapper {
  position: absolute;
  top: 100%;
  padding: 4px 0 0 0;
}

.inlineVerticalWrapper {
  position: relative;
  left: var(--menu-border-width);
}

.menuWrapper {
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
  border-radius: var(--general-border-radius);
  min-width: 12.5rem;
}

.popupSubMenu {
  li:first-child {
    & > * {
      border-radius: var(--general-border-radius) var(--general-border-radius) 0
        0;
    }
  }

  li:last-child {
    & > * {
      border-radius: 0 0 var(--general-border-radius)
        var(--general-border-radius);
    }
  }
}

.verticalOpened {
  transform: rotate(-90deg);
}

.horizontalOpened {
  transform: rotate(180deg);
}

.verticalAngle {
  margin-left: auto;
}

.subMenuSuffix {
  width: auto;
  height: auto;
}

.popupScrollbarWrapper {
  background-color: transparent;
  min-width: 12.5rem;

  & > div {
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
    border-radius: var(--general-border-radius);
    background-color: var(--basic-100);
  }
}

.hidden {
  display: none !important;
}

.padded {
  padding-left: 4px;
}

.bg {
  background: var(--basic-100);
}

.left {
  right: 0;
  left: auto;
}
