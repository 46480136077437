.sidebar {
  position: absolute;
  top: 0;
  clip-path: inset(0px -70px 0px 0px);
  transition: width, min-width 0.3s ease-out;
  background-color: var(--chakra-colors-primary-800);
  width: 0;
  min-width: 0;
  height: 100vh;

  &.open {
    box-shadow: var(--chakra-shadows-3xl);
    min-width: var(--page-sidebar-width);
  }

  &.mobile {
    height: 100dvh;
  }

  @media all and (min-width: 768px) {
    top: auto;
    height: calc(100vh - 132px);

    &.mobile {
      height: calc(100dvh - 132px);
    }
  }

  @media all and (min-width: 1281px) {
    position: relative;
    height: calc(100vh - 148px);

    &.mobile {
      height: calc(100dvh - 148px);
    }

    &.open {
      min-width: 300px;
    }

    &.childSize {
      height: calc(100vh - 142px);

      &.mobile {
        height: calc(100dvh - 142px);
      }
    }
  }

  @media print {
    display: none;
  }
}

.scrollBar {
  &::-webkit-scrollbar {
    background-color: rgba(255, 255, 255, 0.15);
    width: 10px;
    height: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background: var(--chakra-colors-primary-300);
  }
}

.highContrastScrollBar {
  &::-webkit-scrollbar {
    background-color: #a0aec266;
    width: 10px;
    height: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background: #72e9fc;
  }
}
.opener {
  position: absolute;
  right: 0;
  transition: var(--global-transition);
  padding: 0;

  &:hover {
    background: transparent;
  }

  &[data-focus-visible] {
    position: absolute;
  }
}

.content {
  display: flex;
  flex-direction: column;
  visibility: hidden;
  transition: visibility 0.3s;
  min-width: var(--page-sidebar-width);
  height: 100%;

  .sidebar.open & {
    visibility: visible;
  }

  @media all and (min-width: 1281px) {
    min-width: 300px;
  }
}

.highContrast {
  background-color: var(--chakra-colors-secondary-1000);
}
