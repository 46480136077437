.container:hover,
.container[data-hover] {
  & :global(.link-overlay-wrapper),
  & :global(.chakra-image) {
    opacity: 0.5;
  }
  :global(.chakra-aspect-ratio)::after {
    word-wrap: break-word;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    width: 100%;
    overflow: hidden;
    content: 'Click here \a to view details';
    color: var(--primary-800);
    font-style: normal;
    font-weight: 700;
    font-size: 1rem;
    line-height: 1.625rem;
    font-family: 'Verdana', 'Lato', 'Helvetica Neue', Arial, Helvetica,
      sans-serif;
    text-align: center;
    white-space: pre-wrap;
  }
}

.studentK2Container:hover,
.studentK2Container[data-hover] {
  :global(.chakra-aspect-ratio)::after {
    font-family: 'Report', 'Lato', 'Helvetica Neue', Arial, Helvetica,
      sans-serif;
  }
}

.studentK2Container img {
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}

.studentK2Container a::before {
  border-radius: 10px;
}

.highContrast:hover,
.highContrast[data-hover] {
  :global(.chakra-aspect-ratio)::after {
    color: white;
  }
}
