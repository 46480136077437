.errorMessage {
  width: 100%;
  margin-top: 1.5rem;
  border-color: transparent !important;
  padding: calc(var(--global-grid-size) * 2) calc(var(--global-grid-size) * 3);

  & > div:first-child {
    padding-right: calc(var(--global-grid-size) * 2);
  }
}

.text {
  width: 100%;
  color: var(--danger-700);
}

.wrapper {
  width: 100%;
  max-width: none;
  margin-top: calc(var(--global-grid-size) * 2);

  & > div[class*='UI_Message_textHolder'] {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }
}
