.wrapper {
  position: relative;
}

.item {
  display: inline-flex;
  position: relative;
  align-items: center;
  box-sizing: border-box;
  background: transparent;
  color: var(--basic-600);
  text-decoration: none;

  &[data-focus-visible] {
    outline: none;
    box-shadow: 0 0 0 var(--outline-width) var(--outline-color);
    color: var(--primary-400);
  }

  &:hover {
    color: var(--primary-400);
  }
}

.itemSmall {
  padding: var(--menu-item-sm-padding);
  font-weight: var(--menu-item-sm-font-weight);
  font-size: var(--menu-item-sm-font-size);
  line-height: var(--menu-item-sm-line-height);
}

.itemMedium {
  padding: var(--menu-item-md-padding);
  font-weight: var(--menu-item-md-font-weight);
  font-size: var(--menu-item-md-font-size);
  line-height: var(--menu-item-md-line-height);
}

.itemLarge {
  padding: var(--menu-item-lg-padding);
  font-weight: var(--menu-item-lg-font-weight);
  font-size: var(--menu-item-lg-font-size);
  line-height: var(--menu-item-lg-line-height);
}

.verticalItem {
  padding: 0;
  width: 100%;
}

.verticalBorderItem {
  left: var(--menu-border-width);

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    border-radius: 2rem;
    width: var(--menu-border-width);
    height: 100%;
    content: '';
  }

  &:hover {
    &::after {
      display: block;
      background: var(--primary-200);
    }
  }

  &[data-focus-visible] {
    &::after {
      display: block;
      background: var(--primary-500);
    }
  }
}

.horizontalBorderItem {
  top: var(--menu-border-width);

  &::after {
    position: absolute;
    bottom: 0;
    left: 0;
    border-radius: 2rem;
    width: 100%;
    height: var(--menu-border-width);
    content: '';
  }

  &:hover {
    &::after {
      display: block;
      background: var(--primary-200);
    }
  }

  &[data-focus-visible] {
    &::after {
      display: block;
      background: var(--primary-500);
    }
  }
}
