.addIcon:hover {
  cursor: pointer;
}

.viewButtonGroup {
  border: var(--button-border-width) solid var(--blue-5);
  border-radius: var(--global-border-radius);
}

.viewModeBtn {
  border-radius: 0;
  height: 2.75rem;
  min-height: unset;
}

.viewModeBtn:nth-child(2) {
  margin-left: 0;
  border: none;
}

.viewModeBtn:last-child {
  margin-left: 0;
  border: none;
}

.viewModeBtn:not(:last-child) {
  border-right: 1px solid var(--blue-5);
  border-left: none;
  border-block: none;
}
