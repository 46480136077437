:global {
  .video-js {
    max-width: 100%;

    legend {
      font-size: var(--chakra-fontSizes-md);
      line-height: 1.2;
    }

    select {
      position: relative;
      -moz-appearance: none;
      -webkit-appearance: none;
      appearance: none;
      margin: 0 4px 16px 0;
      border: 1px solid;
      border-radius: var(--chakra-radii-md);
      background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23131313%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E');
      background-position: right 0.7rem top 50%;
      background-size: 0.65rem auto;
      background-repeat: no-repeat;
      padding-inline-start: var(--chakra-space-4);
      padding-inline-end: 1.7rem /* --chakra-space-4 + 0.7rem for icon */;
      height: var(--chakra-sizes-10);
      color: black;
      font-size: var(--chakra-fontSizes-md);
      line-height: 1.2;
    }

    .vjs-big-play-button {
      top: calc(50% - 24px);
      left: calc(50% - 45px);
      width: 90px;
      height: 48px;
    }

    .vjs-menu {
      width: 120px;
    }

    .vjs-menu-item-text {
      text-transform: capitalize;
    }

    .vjs-modal-dialog.vjs-text-track-settings {
      height: 100%;
    }

    .vjs-track-settings-controls {
      button {
        border-radius: var(--chakra-radii-md);
        background-image: none;
        padding-inline-start: var(--chakra-space-4);
        padding-inline-end: var(--chakra-space-4);
        min-width: var(--chakra-sizes-10);
        height: var(--chakra-sizes-10);
        font-size: var(--chakra-fontSizes-md);
        line-height: 1.2;
      }
    }

    .vjs-play-progress > .vjs-time-tooltip {
      visibility: hidden !important;
    }

    .vjs-mouse-display > .vjs-time-tooltip {
      position: relative;

      &::after {
        display: flex;
        position: absolute;
        bottom: 0;
        left: 0;
        justify-content: center;
        align-items: center;
        background: rgba(245, 247, 247);
        width: 100%;
        height: 24px;
        content: attr(data-time);
      }
    }

    /* Light theme overrides */

    .vjs-control-bar {
      background: rgba(245, 247, 247, 0.9);
    }

    .vjs-play-control,
    .vjs-seek-button,
    .vjs-mute-control,
    .vjs-volume-level,
    .vjs-play-progress,
    .vjs-remaining-time,
    .vjs-subs-caps-button,
    .vjs-fullscreen-control {
      color: #464646;
    }

    .vjs-volume-level,
    .vjs-play-progress {
      background: #464646;
    }

    .vjs-volume-tooltip {
      background: white;
      color: black;
      font-size: 16px !important;
    }

    .vjs-subs-caps-button {
      .vjs-menu {
        left: -75px;
        width: 150px !important;
      }

      .vjs-menu-content {
        border-radius: var(--chakra-radii-md);
        background: white !important;
      }
      .vjs-menu-item {
        transition: background-color 200ms linear;
        background-color: white !important;
        color: black !important;
        font-size: 16px;
        white-space: nowrap;

        &:hover {
          background-color: rgba(0, 0, 0, 0.2) !important;
        }
      }
      .vjs-selected {
        background-color: black !important;
        color: white !important;
      }
    }
  }
}
