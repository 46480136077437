.wrapper {
  position: absolute;
  z-index: 11;
  padding-top: var(--global-grid-size);
  width: 100%;
  min-width: calc(var(--global-grid-size) * 25);
  max-width: calc(var(--global-grid-size) * 37);

  :global(.theme-high-contrast) & {
    border: var(--global-border);
  }
}

.wrapperClosed {
  display: none;
}
