.wrapper {
  position: relative;
  justify-content: end;
  align-items: center;
  box-shadow: var(--chakra-shadows-2xl);
  border: var(--global-border);
  background-color: var(--basic-100);
  padding: var(--global-grid-size) calc(var(--global-grid-size) * 3);
  color: var(--blue-5);

  & .title {
    margin-right: var(--global-grid-size);
    min-width: calc(var(--global-grid-size) * 20);
    overflow: hidden;
    font-weight: 700;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
