.wrapper {
  @media print {
    display: none;
  }
}

.logoImage {
  display: inline;
  width: 161px;
  height: 38px;
}

.mobileLogoImage {
  display: inline;
  width: 110px;
  height: 26px;
}

.content {
  position: relative;
  align-items: center;
  margin: 0 auto;
  width: 100%;
  max-width: var(--page-width-max);
  color: var(--basic-100);
}

.logo {
  display: flex;
  flex-shrink: 0;
  width: fit-content;
  height: auto;

  img {
    display: block;
    width: 100%;
    height: auto;
  }
}

.skipLink {
  display: flex;
  position: absolute;
  bottom: calc(-1 * (100% + var(--global-grid-size) * 2));
  left: var(--global-gap);
  justify-content: center;
  align-items: center;
  transform: translateY(calc(-1 * (100% + var(--global-grid-size)) * 3));
  z-index: 1;
  transition: var(--global-transition);
  border-radius: var(--global-border-radius);
  background-color: var(--basic-100);
  padding: 0 calc(var(--global-grid-size) * 2);
  height: auto;
  min-height: var(--page-header-height);

  &:link {
    color: var(--primary-500);
  }

  &:visited {
    color: var(--info-700);
  }

  &:hover {
    color: var(--primary-400);
    text-decoration: none;
  }

  &:active {
    color: var(--primary-600);
  }
}
