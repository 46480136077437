.wrapper {
  display: flex;
  position: relative;
  flex-direction: row;
  transition: var(--global-transition);
  border-radius: var(--global-border-radius);
  background-color: var(--white);
  width: 100%;
  height: 57px;
  overflow: hidden;

  &:focus-within,
  &:hover {
    transform: translateY(calc(-1 * var(--global-grid-size) / 4));
  }
}

.highContrastWrapper {
  border: 1px solid white;
  background-color: var(--chakra-colors-secondary-1000);
}

.imageWrapper {
  position: relative;
  background-color: var(--grey-2);
  width: 104px;
  overflow: hidden;
}

.highContrastImageWrapper {
  border-right: 1px solid white;
}

.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.brokenImage {
  &::before {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    background-color: var(--grey-2);
    width: 100%;
    height: 100%;
    content: '';
  }

  &::after {
    -webkit-box-orient: vertical;
    -moz-box-orient: vertical;
    -webkit-line-clamp: 5;
    display: -webkit-box;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    padding: 0 var(--global-grid-size);
    width: 100%;
    max-height: calc(var(--typography-p2-line-height) * 5);
    overflow: hidden;
    color: var(--basic-800);
    font-weight: var(--typography-p2-font-weight);
    font-size: var(--typography-p2-font-size);
    line-height: var(--typography-p2-line-height);
    font-family: var(--font-type-main);
    text-align: center;
    white-space: pre-wrap;
  }
}

.main {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: calc(var(--global-grid-size) * 5);
  padding-left: var(--global-grid-size);
  width: 100%;
  color: var(--blue-5);
}

.highContrastMain {
  color: white;
}

.text {
  display: flex;
  align-items: center;
}

.titleLink {
  display: block;
  cursor: pointer;
  color: var(--blue-5);
  text-decoration: none;
  word-break: break-word;

  &:focus {
    outline-color: transparent;
    outline-style: solid;
  }

  &[data-focus-visible] {
    &::before {
      box-shadow: var(--global-outline);
    }
  }

  &::before {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    border-radius: var(--global-border-radius);
    width: 100%;
    height: 100%;
    content: '';
  }

  &::after {
    content: '\00a0\00a0\00a0\00a0\00a0\00a0';
  }
}

.highContrastTitleLink {
  color: white;
}

.contentItem {
  color: var(--blue-4);
  font-weight: normal;

  &::after {
    content: '\00a0\00a0\00a0\00a0';
  }
}

.highContrastContentItem {
  color: white;
}
