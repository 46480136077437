.container {
    width: 100%;
    height: 26px;
    text-align: center;
    border: none;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.item {
    height: 12px;
    width: 12px;
    border-radius: 2px;
    margin-right: .5rem;
    margin-left: .5rem;
    position: relative;
    -webkit-transform: scale(.3);
    background: #1e486f;
}

.item1 {
    -webkit-animation: scale 2s 0s infinite;
}

.item2 {
    -webkit-animation: scale 2s .2s infinite;
}

.item3 {
    -webkit-animation: scale 2s .4s infinite;
}

.item4 {
    -webkit-animation: scale 2s .6s infinite;
}

.item5 {
    -webkit-animation: scale 2s .8s infinite;
}

.item6 {
    -webkit-animation: scale 2s 1s infinite;
}

.item7 {
    -webkit-animation: scale 2s 1.2s infinite;
}

.item8 {
    -webkit-animation: scale 2s 1.4s infinite;
}

.item9 {
    -webkit-animation: scale 2s 1.6s infinite;
}

.item10 {
    -webkit-animation: scale 2s 1.8s infinite;
}

@keyframes scale {
    0% 		 { -webkit-transform: scale(.3);}
    35%      { -webkit-transform: scale(1);}
    65%      { -webkit-transform: scale(.3);}
    100%     { -webkit-transform: scale(.3);}
}