.wrapper {
  --switch-height: 1.5rem;
  --switch-width: 2.75rem;
  --switch-thumb-size: calc(var(--switch-height) - 1px * 2);
  grid-gap: calc(var(--global-grid-size) / 2) calc(var(--global-grid-size) * 2);

  display: grid;
  grid-template-columns: min-content 1fr;
  align-items: center;
}

.title {
  grid-column: 1 / -1;
}

.button {
  display: block;
  position: relative;
  flex-shrink: 0;
  transition: background-color 0.3s ease-out;
  cursor: pointer;
  border: none;
  border-radius: calc(var(--switch-height) / 2);
  background-color: var(--white);
  width: var(--switch-width);
  height: var(--switch-height);

  &:hover {
    background-color: var(--white);
  }

  &[aria-checked='false'] {
    &::before {
      left: 1px;
    }
  }

  &[aria-checked='true'] {
    background-color: var(--white);

    &:hover {
      background-color: var(--white);
    }

    &::before {
      left: calc(100% - var(--switch-thumb-size) - 1px);
    }
  }

  &[disabled],
  &[disabled]:hover {
    cursor: not-allowed;
    background-color: var(--white);
  }

  &:focus {
    outline-color: transparent;
    outline-style: solid;
  }

  &[data-focus-visible] {
    box-shadow: var(--global-outline);
  }

  &::before {
    position: absolute;
    top: 1px;
    transition: var(--global-transition);
    border-radius: 50%;
    background-color: var(--blue-5);
    width: var(--switch-thumb-size);
    height: var(--switch-thumb-size);
    content: '';
  }
}
